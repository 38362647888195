<template>
  <div class="home">
    <Header headerNum="home" :bgShow="head=='B'? true:false" :headWier="headWier" />

    <div>
      <div class="home_title" style="height:50vw">
        <div class="home_title_banner">
          <el-carousel height="50vw" arrow="never">
            <el-carousel-item v-for="(item,index) in banner" :key="index">
              <img :src="item" alt width="100%" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 新闻要点 -->
      <div class="centre box" style="margin-top:3.2vw">
        <el-row>
          <el-col :md="16" :xs="24" :sm="24" :lg="16" :xl="16">
            <el-row>
              <el-col :md="23" :xs="24" :sm="24" :lg="23" :xl="23" class="centre_left">
                <div class="centre_left_title" @click="newsChange(info.part1.p1[0].cateid)">
                  <div class="centre_left_title_hotspot">
                    <p class="centre_left_title_hotspot_dat">热点要闻</p>
                    <p class="centre_left_title_hotspot_hot">HOT NEWS</p>
                  </div>
                  <div class="centre_left_title_loock">
                    <p>查看更多</p>
                    <img src="../assets/loock-right.png" alt width="100%" />
                  </div>
                </div>
                <!-- 轮播图 -->
                <el-carousel height="34vw"  arrow="never" ref="newsBanner" class="bannerImg">
                  <el-carousel-item v-for="(item,index) in info.part1.p1" :key="index">
                    <div @click="newsTlents(item)">
                      <img :src="item.thumb[0]? item.thumb[0]:''" alt width="100%" />
                      <div class="bannerImg_text">
                        <div class="bannerImg_text_title">{{ item.title? item.title:'' }}</div>
                        <div class="bannerImg_text_txt">{{ item.desc? item.desc:''}}...</div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <div class="bannerImg_arrows">
                    <img
                      src="../assets/banner-left.png"
                      @click="newsBannerChange('prev')"
                      width="100%"
                      alt
                    />
                    <img
                      src="../assets/banner-right.png"
                      @click="newsBannerChange('next')"
                      width="100%"
                      alt
                    />
                  </div>
                </el-carousel>

                <el-row type="flex" justify="space-between" class="centre_left_bottom">
                  <el-col
                    :span="7"
                    v-for="(item,index) in info.part1.p3"
                    :key="index"
                    class="centre_left_bottom_item"
                    v-show="index<3"
                  >
                    <div @click="newsTlents(item)">
                      <el-col :span="24" class="centre_left_bottom_item_img">
                        <div class="images">
                          <img :src="item.thumb[0]" alt width="100%" />
                        </div>
                        
                        <div class="centre_left_bottom_item_img_text">
                          <p>{{ item.ctime.split(' ')[0].split('-')[2] }}</p>
                          <p>{{ item.ctime.split(' ')[0].split('-')[0] }}.{{ item.ctime.split(' ')[0].split('-')[1] }}</p>
                        </div>
                      </el-col>
                      <el-col :span="1" class="centre_left_bottom_item_wier"></el-col>
                      <el-col :span="23" class="centre_left_bottom_item_text">{{ item.title }}</el-col>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="1"></el-col>
            </el-row>
          </el-col>

          <el-col
            :md="8"
            :xs="24"
            :sm="24"
            :lg="8"
            :xl="8"
            class="centre_right"
            style="cursor: pointer;"
          >
            <el-col :span="24">
              <div class="centre_left_title" @click="newsChange(info.part1.p2[0].cateid)">
                <div class="centre_left_title_hotspot">
                  <p class="centre_left_title_hotspot_dat">学校要闻</p>
                  <p class="centre_left_title_hotspot_hot">ACADEMY NEWS</p>
                </div>
                <div class="centre_left_title_loock">
                  <p>查看更多</p>
                  <img src="../assets/loock-right.png" alt width="100%" />
                </div>
              </div>
              <el-col :span="24" style="margin-bottom:0.78vw;">
                <div class="imgss">
                <img
                  :src="info.part1.p2[0].thumb[0]"
                  alt
                  width="100%"
                  @click="newsTlents(info.part1.p2[0])"
                />
              </div>
              </el-col>
              <el-col
                :span="24"
                class="centre_right_top"
                v-for="(item,index) in info.part1.p2"
                :key="index"
                v-show="index<2"
              >
                <div class="centre_right_top_time" @click="newsTlents(item)">
                  <p>{{ item.ctime.split(' ')[0] }}</p>
                  <div class="centre_right_top_time_wier"></div>
                </div>
                <div class="centre_right_top_wier"></div>
                <div class="centre_right_top_text" @click="newsTlents(item)">
                  <p>{{item.title}}</p>
                  <p>{{item.desc}}</p>
                </div>
              </el-col>
            </el-col>

            <el-col :span="24">
              <div
                class="centre_left_title"
                style="margin-top: 0.93vw;"
                @click="newsChange(info.part1.p4[0].cateid)"
              >
                <div class="centre_left_title_hotspot">
                  <p class="centre_left_title_hotspot_dat">校园动态</p>
                  <p class="centre_left_title_hotspot_hot">CAMPUS DYNAMLCS</p>
                </div>
                <div class="centre_left_title_loock">
                  <p>查看更多</p>
                  <img src="../assets/loock-right.png" alt width="100%" />
                </div>
              </div>
              <el-col :span="24">
                <div class="imgss">
                <img
                  :src="info.part1.p4[0].thumb[0]"
                  alt
                  width="100%"
                  @click="newsTlents(nfo.part1.p4[0])"
                />
              </div>
              </el-col>
              <el-col
                :span="24"
                class="centre_right_top_box"
                v-for="(item,index) in info.part1.p4"
                :key="index"
              >
                <div class="centre_right_top_vertical"></div>
                <div class="centre_right_top_text" @click="newsTlents(item)">
                  <p>{{item.title}}</p>
                  <p>{{item.desc}}</p>
                </div>
              </el-col>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <!-- 学院风采  -->
      <div class="box" v-if="xuwyuan">
        <div class="centre_left_title" @click="mienChange">
          <div class="centre_left_title_hotspot">
            <p class="centre_left_title_hotspot_dat">学院风采</p>
            <p class="centre_left_title_hotspot_hot">ACADEMY NEWS</p>
          </div>
          <div class="centre_left_title_loock">
            <p>查看更多</p>
            <img src="../assets/loock-right.png" alt width="100%" />
          </div>
        </div>
      </div>
      <div class="box mien">
        <div
          class="mien_item mien_item_text"
          v-if="!xuwyuan"
          :style="'height:'+mienH+'px;overflow: hidden;'"
        >
          <div class="mien_item_text_title">
            <div class="mien_item_text_title_txt">学院风采</div>
            <div class="mien_item_text_title_hint">
              <div class="mien_item_text_title_hint_wier"></div>
              <div class="mien_item_text_title_hint_english">ACADEMY NEWS</div>
            </div>
          </div>
          <div
            class="mien_item_text_loock"
            style="align-items: center;justify-content: center;cursor: pointer;"
            @click="mienChange"
          >
            <div>
              <img src="../assets/loock-right.png" style=" width: 1.5vw;" alt width="100%" />
            </div>
            <div class="mien_item_text_loock_text">查看更多</div>
          </div>
        </div>

        <div
          class="mien_item"
          :style="'height:'+mienH+'px;'"
          v-for="(item,index) in info.part2"
          :key="index"
        >
          <div class="mien_item_img">
            <img :src="item.thumb[0]" alt width="100%" />
            <div class="mien_item_img_text">{{ item.title}}</div>
          </div>
        </div>
      </div>
      <!-- 教学科研 -->
      <div>
        <div class="teach">
          <div class="box teach_top">
            <div class="teach_box">
              <div class="teach_box_title">
                <div class="teach_box_title_logo">
                  <div
                    :class="checkoutshow?'teach_box_title_logo_img':''"
                    @click="checkoutshow=true"
                  >
                    <div
                      :class="checkoutshow?'teach_box_title_logo_img_text':'teach_box_title_logo_text'"
                    >{{info.part3[0].title}}</div>
                  </div>

                  <div
                    :class="!checkoutshow?'teach_box_title_logo_img':''"
                    @click="checkoutshow=false"
                    style="margin-left:2.76vw;"
                  >
                    <div
                      :class="!checkoutshow?'teach_box_title_logo_img_text':'teach_box_title_logo_text'"
                    >{{info.part3[1].title}}</div>
                  </div>

                  <!-- <div class="teach_box_title_logo_text" @click="checkoutshow=false">工作动态</div> -->
                </div>
                <div
                  class="teach_box_title_loock"
                  @click="newsChange(checkoutshow? info.part3[0].cateid:info.part3[1].cateid)"
                >
                  <p>查看更多</p>
                  <img src="../assets/loock-right.png" alt style="width:1.51vw;height: 0.46vw;" />
                </div>
              </div>
              <!-- 教学科研 -->
              <div class="teach_box_IText" v-if="checkoutshow">
                <div
                  style="cursor: pointer;"
                  class="teach_box_IText_item"
                  v-for="(item,index) in info.part3[0].list"
                  :key="index"
                  v-show="index<2"
                  @click="newsTlents(item)"
                  @mouseenter="mouseeIndex=item.id"
                  @mouseleave="mouseeIndex=0"
                >
                  <div class="teachImg">
                    <img :src="item.thumb[0]" alt width="100%" />
                  </div>
                  <div class="teachText">
                    <p
                      class="teachText_title"
                      :class="mouseeIndex==item.id? 'teachText_action':''"
                    >{{item.title}}</p>
                    <p class="teachText_text">{{item.desc}}</p>
                    <p
                      class="teachText_time"
                    >{{ item.ctime.split(' ')[0].split('-')[0] }}.{{ item.ctime.split(' ')[0].split('-')[1] }}.{{ item.ctime.split(' ')[0].split('-')[2] }}</p>
                    <img
                      v-if="mouseeIndex==item.id"
                      src="../assets/loock-right.png"
                      alt
                      style="width: 1.51vw;height:0.47vw;margin-top: 2.24vw;"
                    />
                    <img
                      v-else
                      src="../assets/loock-right2.png"
                      alt
                      style="width: 1.51vw;height:0.47vw;margin-top: 2.24vw;"
                    />
                  </div>
                </div>
              </div>
              <!-- 工作动态 -->
              <div class="teach_box_IText" v-if="!checkoutshow">
                <div
                  class="teach_box_IText_item"
                  v-for="(item,index) in info.part3[1].list"
                  :key="index"
                  v-show="index<2"
                  @click="newsTlents(item)"
                  style="cursor: pointer;"
                  @mouseenter="mouseeIndex=item.id"
                  @mouseleave="mouseeIndex=0"
                >
                  <div class="teachImg">
                    <img :src="item.thumb[0]" alt width="100%" />
                  </div>
                  <div class="teachText">
                    <p
                      class="teachText_title"
                      :class="mouseeIndex==item.id? 'teachText_action':''"
                    >{{item.title}}</p>
                    <p class="teachText_text">{{item.desc}}</p>
                    <p
                      class="teachText_time"
                    >{{ item.ctime.split(' ')[0].split('-')[0] }}.{{ item.ctime.split(' ')[0].split('-')[1] }}.{{ item.ctime.split(' ')[0].split('-')[2] }}</p>
                    <img
                      v-if="mouseeIndex==item.id"
                      src="../assets/loock-right.png"
                      alt
                      style="width: 1.51vw;height:0.47vw;margin-top: 2.24vw;"
                    />
                    <img
                      v-else
                      src="../assets/loock-right2.png"
                      alt
                      style="width: 1.51vw;height:0.47vw;margin-top: 2.24vw;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="teach_notice">
              <div class="centre_left_title">
                <div class="centre_left_title_hotspot">
                  <p class="centre_left_title_hotspot_dat">{{info.part3[2].title}}</p>
                  <p class="centre_left_title_hotspot_hot">NOTICE</p>
                </div>
              </div>
              <div
                class="teach_notice_item"
                style="cursor: pointer;"
                v-for="(item,index) in info.part3[2].list"
                :key="index"
                @click="newsTlents(item)"
              >
                <div class="teach_notice_item_time">
                  <p>{{ item.ctime.split(' ')[0].split('-')[2] }}</p>
                  <p>{{ item.ctime.split(' ')[0].split('-')[0] }}.{{ item.ctime.split(' ')[0].split('-')[1] }}</p>
                </div>
                <div class="teach_notice_item_wier"></div>
                <div class="teach_notice_item_text">
                  <p>{{ item.title }}</p>
                </div>
              </div>
              <div
                class="teach_notice_loock"
                @click="newsChange( info.part3[2].cateid)"
                style="cursor: pointer;"
              >
                <p>查看更多</p>
                <img
                  src="../assets/loock-right.png"
                  alt
                  style="width: 1.51vw;height:0.47vw;margin-left: 0.36vw;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 视频 -->
      <div class="box video">
        <div
          style="  border: 0.93vw solid #ffffff;box-shadow: 0px 0px 4.06vw 0.31vw rgba(0,0,0,0.14);"
        >
          <el-row>
            <el-col :span="24" style="display:flex;justify-content: center;">
              <!-- <PlayerVideo :src="videoUrl" style="width: 100%;"></PlayerVideo> -->
              <!-- autoplay 视频自动播放 -->
              <video
                :src="videoUrl"
                controls
                loop
                muted
                style="width: 100%;max-height: 45vw;"
                :poster="videoImg"
              ></video>
            </el-col>
            <el-col :span="24" class="video_banner" tyle="flex" justify="space-between">
              <el-tag
                class="video_banner_item"
                v-for="(item,index) in VBanner"
                :key="index"
                :style="index==vindex? 'border:0.41vw solid #A31181;':'border:0.41vw solid transparent'"
                v-show="vindex==0? index<4:vindex<4? index<4:index-3&&index"
              >
                <img
                  :src="item.thumb[0]"
                  alt
                  width="100%"
                  height="100%"
                  @click="ImgBanChange(index)"
                  style="cursor: pointer;"
                />
              </el-tag>

              <el-tag class="video_banner_left" style="cursor: pointer;" @click="vBanChange('up')">
                <img src="../assets/banner-left.png" alt width="100%" />
              </el-tag>
              <el-tag
                class="video_banner_right"
                style="cursor: pointer;"
                @click="vBanChange('below')"
              >
                <img src="../assets/banner-right.png" alt width="100%" />
              </el-tag>
            </el-col>
            <el-col :span="24" class="video_text">
              <el-col class="video_text_item" v-for="(item,index) in info.part5" :key="index">
                <p @click="videoTextChange(item)">{{item.title}}</p>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 学校寄语 -->
      <div class="box send">
        <el-row>
          <el-col :span="24">
            <div class="centre_left_title">
              <div class="centre_left_title_hotspot">
                <p class="centre_left_title_hotspot_dat">学院寄语</p>
                <p class="centre_left_title_hotspot_hot">NESSAGE</p>
              </div>
              <div class="centre_left_title_loock" style="cursor: pointer;" @click="wishes">
                <p>查看更多</p>
                <img
                  src="../assets/loock-right.png"
                  style="width: 1.5vw;height:0.6vw;"
                  alt
                  width="100%"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="send_text">
          <el-col :md="9" :xs="24" :sm="24" :lg="9" :xl="9">
              <img src="../assets/send.png" alt width="100%" />
          </el-col>
          <el-col :md="8" :xs="24" :sm="24" :lg="8" :xl="8" class="send_text_box">
            <div class="send_text_item">
              <p>{{info.part6[0].content}}</p>
            </div>
            <div class="send_text_item">
              <p>{{info.part6[1].content}}</p>
            </div>
          </el-col>
          <el-col :md="8" :xs="24" :sm="24" :lg="8" :xl="8" class="send_text_box">
            <div class="send_text_item">
              <p>{{info.part6[2].content}}</p>
            </div>
            <div class="send_text_item">
              <p>{{info.part6[3].content}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 社交 -->
      <di class="social">
        <div class="box social_box">
          <el-row>
            <el-col :span="24">
              <div class="centre_left_title">
                <div class="centre_left_title_hotspot">
                  <p class="centre_left_title_hotspot_dat">社会媒体上的我们</p>
                  <p class="centre_left_title_hotspot_hot">NESSAGE</p>
                </div>
                <div class="media">
                  <div class="media_item">
                    <el-popover placement="top-start" width="170" trigger="hover">
                      <div class="content_right_item" slot="reference">
                        <img src="../assets/media1.png" alt width="100%" />
                      </div>
                      <div class="QRcode" slot>
                        <img :src="qrImg.douyin" alt width="100%" />
                      </div>
                    </el-popover>
                  </div>
                  <div class="media_item">
                    <el-popover placement="top-start" width="170" trigger="hover">
                      <div class="content_right_item" slot="reference">
                        <img src="../assets/media2.png" alt width="100%"  />
                      </div>
                      <div class="QRcode" slot>
                        <img :src="qrImg.weibo" alt width="100%" />
                      </div>
                    </el-popover>
                  </div>
                  <div class="media_item">
                    <el-popover placement="top-start" width="170" trigger="hover">
                      <div class="content_right_item" slot="reference">
                        <img src="../assets/media3.png" alt width="100%" />
                      </div>
                      <div class="QRcode" slot>
                        <img :src="qrImg.wechat" alt width="100%" />
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="social_box_imgs">
            <el-col :md="6" :xs="12" :sm="12" :lg="6" :xl="6" class="social_box_imgs_item">
              <img :src="info.part7[0].thumb" alt width="100%" height="100%" />
              <div class="social_box_imgs_item_text">
                <div class="social_box_imgs_item_top">
                  <p>{{info.part7[0].ctime}}</p>
                  <p>{{info.part7[0].title}}</p>
                </div>
                <div class="social_box_imgs_item_bottom">
                  <img
                    :style="info.part7[0].from!='douyin'?'width:1.1vw;height:0.88vw;':''"
                    :src="info.part7[0].from=='douyin'?require('../assets/media1-1.png'):info.part7[0].from=='weibo'?require('../assets/media2-2.png'):require('../assets/media3-3.png')"
                    alt
                    width="100%"
                  />
                  <p>{{info.part7[0].from=='douyin'?'抖音':info.part7[0].from=='weibo'?'微博':'微信'}}</p>
                </div>
              </div>
            </el-col>
            <el-col :md="6" :xs="12" :sm="12" :lg="6" :xl="6" class="social_box_imgs_item">
              <img :src="info.part7[1].thumb" alt width="100%" height="100%"/>
              <div class="social_box_imgs_item_text">
                <div class="social_box_imgs_item_top">
                  <p>{{info.part7[1].ctime}}</p>
                  <p>{{info.part7[1].title}}</p>
                </div>
                <div class="social_box_imgs_item_bottom">
                  <img
                    :style="info.part7[1].from!='douyin'?'width:1.1vw;height:0.88vw;':''"
                    :src="info.part7[1].from=='douyin'?require('../assets/media1-1.png'):info.part7[1].from=='weibo'?require('../assets/media2-2.png'):require('../assets/media3-3.png')"
                    alt
                    width="100%"
                  />
                  <p>{{info.part7[1].from=='douyin'?'抖音':info.part7[1].from=='weibo'?'微博':'微信'}}</p>
                </div>
              </div>
            </el-col>
            <el-col :md="12" :xs="24" :sm="24" :lg="12" :xl="12" class="social_box_imgs_item">
              <img :src="info.part7[2].thumb" alt width="100%" height="100%"/>
              <div class="social_box_imgs_item_text">
                <div class="social_box_imgs_item_top">
                  <p>{{info.part7[2].ctime}}</p>
                  <p>{{info.part7[2].title}}</p>
                </div>
                <div class="social_box_imgs_item_bottom">
                  <img
                    :style="info.part7[2].from!='douyin'?'width:1.1vw;height:0.88vw;':''"
                    :src="info.part7[2].from=='douyin'?require('../assets/media1-1.png'):info.part7[2].from=='weibo'?require('../assets/media2-2.png'):require('../assets/media3-3.png')"
                    alt
                    width="100%"
                  />
                  <p>{{info.part7[2].from=='douyin'?'抖音':info.part7[2].from=='weibo'?'微博':'微信'}}</p>
                </div>
              </div>
            </el-col>
            <el-col :md="12" :xs="24" :sm="24" :lg="12" :xl="12" class="social_box_imgs_item">
              <img :src="info.part7[3].thumb" alt width="100%" height="100%"/>
              <div class="social_box_imgs_item_text">
                <div class="social_box_imgs_item_top">
                  <p>{{info.part7[3].ctime}}</p>
                  <p>{{info.part7[3].title}}</p>
                </div>
                <div class="social_box_imgs_item_bottom">
                  <img
                    :style="info.part7[3].from!='douyin'?'width:1.1vw;height:0.88vw;':''"
                    :src="info.part7[3].from=='douyin'?require('../assets/media1-1.png'):info.part7[3].from=='weibo'?require('../assets/media2-2.png'):require('../assets/media3-3.png')"
                    alt
                    width="100%"
                  />
                  <p>{{info.part7[3].from=='douyin'?'抖音':info.part7[3].from=='weibo'?'微博':'微信'}}</p>
                </div>
              </div>
            </el-col>
            <el-col :md="6" :xs="12" :sm="12" :lg="6" :xl="6" class="social_box_imgs_item">
              <img :src="info.part7[4].thumb" alt width="100%" height="100%"/>
              <div class="social_box_imgs_item_text">
                <div class="social_box_imgs_item_top">
                  <p>{{info.part7[4].ctime}}</p>
                  <p>{{info.part7[4].title}}</p>
                </div>
                <div class="social_box_imgs_item_bottom">
                  <img
                    :style="info.part7[4].from!='douyin'?'width:1.1vw;height:0.88vw;':''"
                    :src="info.part7[4].from=='douyin'?require('../assets/media1-1.png'):info.part7[4].from=='weibo'?require('../assets/media2-2.png'):require('../assets/media3-3.png')"
                    alt
                    width="100%"
                  />

                  <p>{{info.part7[4].from=='douyin'?'抖音':info.part7[4].from=='weibo'?'微博':'微信'}}</p>
                </div>
              </div>
            </el-col>
            <el-col :md="6" :xs="12" :sm="12" :lg="6" :xl="6" class="social_box_imgs_item">
              <img :src="info.part7[5].thumb" alt width="100%" height="100%"/>

              <div class="social_box_imgs_item_text">
                <div class="social_box_imgs_item_top">
                  <p>{{info.part7[5].ctime}}</p>
                  <p>{{info.part7[5].title}}</p>
                </div>
                <div class="social_box_imgs_item_bottom">
                  <img
                    :style="info.part7[5].from!='douyin'?'width:1.1vw;height:0.88vw;':''"
                    :src="info.part7[5].from=='douyin'?require('../assets/media1-1.png'):info.part7[5].from=='weibo'?require('../assets/media2-2.png'):require('../assets/media3-3.png')"
                    alt
                    width="100%"
                  />
                  <p>{{info.part7[5].from=='douyin'?'抖音':info.part7[5].from=='weibo'?'微博':'微信'}}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </di>

      <!-- 底部 -->
      <div>
        <Bottom />
      </div>
    </div>
  </div>
</template>

<script>
import { getIndex, getBanner } from "@/utils/api";
import Header from "@/components/header.vue";
import Bottom from "@/components/bottom.vue";
// import PlayerVideo from "@/components/PlayerVideo.vue";
export default {
  name: "HomeView",
  components: {
    Header,
    Bottom
    // PlayerVideo
  },
  data() {
    return {
      xuwyuan: false,
      lv: 1,
      screenWidth: "",
      bannerH: "",
      banner: [], //顶部轮播图
      newH: "",
      mienH: "",
      VBanner: [
        require("../assets/video1.png"),
        require("../assets/video2.png"),
        require("../assets/video1.png"),
        require("../assets/video2.png"),
        require("../assets/video1.png"),
        require("../assets/video1.png"),
        require("../assets/video2.png")
      ], //视频下的轮播图
      info: {},
      checkoutshow: true, //工作动态
      vindex: 0, //视频下轮播图索引
      videoUrl: "",
      videoImg: "",
      mouseeIndex: 0,
      head: "",
      headWier: "",
      qrImg: []
    };
  },
  created() {
    localStorage.setItem("head", "A");
    localStorage.setItem("headWier", 0);
    this.head = localStorage.getItem("head");
    this.headWier = localStorage.getItem("headWier");
    //顶部轮播图
    getBanner({ type: "index" }, res => {
      if (res.code == 0) {
        res.data.list.forEach(item => {
          this.banner.push(item.pic[0]);
        });
      }
    });
    getIndex({}, res => {
      if (res.code == 0) {
        res.data.part7.forEach(item => {
          item.ctime = this.formatDate(item.ctime);
          item.ctime = item.ctime.split(" ");
          item.ctime = item.ctime[0].split("-");
          item.ctime = item.ctime[1] + "." + item.ctime[2];
          item.thumb = item.thumb[0];
        });
        res.data.part4.forEach(item => {
          item.video = JSON.parse(item.video);
        });
        this.videoImg = res.data.part4[0].thumb[0];
        this.videoUrl = res.data.part4[0].video[0].value;
        this.VBanner = res.data.part4;
        this.info = res.data;
      }
    });
  },
  mounted() {
    // 二维码
    setTimeout(() => {
      this.qrImg = config;
    }, 1000);

    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      console.log("高度");
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  watch: {
    screenWidth: function(n) {
      if (n < 968) {
        this.xuwyuan = true;
      } else {
        this.xuwyuan = false;
      }
      var nw = n > 1920 ? 1920 : n;
      this.bannerH = (nw * 967) / 1920;
      this.newH = (nw * 653) / 1920;
      this.mienH = (nw * 668) / 1920;
      this.lv = nw / 1920;
      this.$forceUpdate();

      console.log("屏幕1", n);
    }
  },
  methods: {
    // 新闻要点轮播图切换
    newsBannerChange(e) {
      if (e == "prev") {
        this.$refs.newsBanner.prev();
      } else if (e == "next") {
        this.$refs.newsBanner.next();
      }
    },

    ImgBanChange(e) {
      this.vindex = e;
      this.videoUrl = this.VBanner[this.vindex].video[0].value;
      this.videoImg = this.VBanner[this.vindex].thumb[0];
    },
    // 视频下轮播图事件
    vBanChange(e) {
      if (e == "up") {
        if (this.vindex > 0) {
          this.vindex--;
        }
      } else {
        if (this.vindex < this.VBanner.length - 1) {
          this.vindex++;
        }
      }
      this.videoUrl = this.VBanner[this.vindex].video[0].value;
      this.videoImg = this.VBanner[this.vindex].thumb[0];
      console.log("视频", e, this.VBanner);
    },
    // 新闻列表
    newsChange(e) {
      this.$router.push({ name: "news", params: { cateid: e } });
    },
    // 新闻详情
    newsTlents(item) {
      this.$router.push(
        `/talents?id=${item.id}&type=news&cateid=${item.cateid}`
      );
    },
    // 学院风采查看更多
    mienChange() {
      this.$router.push(`/campus?type=mien`);
    },

    // 学院寄语
    wishes() {
      this.$router.push("/wishes");
    },
    // 视频下标题
    videoTextChange(item) {
      if (item.jump != "none") {
        this.$router.push(`/institution1?id=${item.id}&tempid=${item.tempid}`);
      }
    },
    // 时间搓转转时间
    formatDate(value) {
      if (value == undefined) {
        return;
      }
      // let date = new Date(value * 1000);
      let date = new Date(value);
      //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM; //月补0
      let d = date.getDate();
      d = d < 10 ? "0" + d : d; //天补0
      let h = date.getHours();
      h = h < 10 ? "0" + h : h; //小时补0
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m; //分钟补0
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s; //秒补0
      // return y + '-' + MM + '-' + d; //年月日
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s; //年月日时分秒
    }
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 968px) {
  .home {
    width: 100%;
    min-width: 1024px;
    background-color: #ffffff;
    overflow: hidden;
    &_bg {
      background: url(http://handan-oss.oss-cn-beijing.aliyuncs.com/20240409/797420c78be581da8d5a8d6ccc0e9b27.png) no-repeat;
      background-size: 100% 100%;
    }

    &_title {
      width: 100%;
      position: relative;
      &_banner {
        min-width: 1024px;
        height: 50vw;
      }

      &_box {
        background: url(http://handan-oss.oss-cn-beijing.aliyuncs.com/20240409/797420c78be581da8d5a8d6ccc0e9b27.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        height: 12.18vw;
        margin: auto;

        &_hint {
          width: 100%;
          height: 80%;
          min-width: 1024px;
          max-width: 1920px;
          margin: auto;
          display: flex;
          justify-content: space-between;
          padding: 1.4vw 3.59vw 0 3vw;
        }
        &_left {
          margin-top: 0.52vw;
        }
        &_right {
          flex: 1;
          &_bottom {
            flex: 1;
          }
        }
      }
    }
  }
  .imgss{
    max-height: 214px !important;
    height:11.1vw !important;
    overflow: hidden;
  }
  .centre {
    &_left {
      // width: 100%;
      cursor: pointer;
      &_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_hotspot {
          width: 6.4vw;
          height: 6.4vw;
          background: url(../assets/logo-bg.png) no-repeat;
          background-size: 100% 100%;
          padding-top: 1.45vw;
          &_dat {
            white-space: nowrap;
            font-size: max(12px, calc(100vw * 34px / 1920px));
            color: #5d216b;
            font-weight: bold;
            letter-spacing: 0.1vw;
            margin-left: -0.26vw;
          }
          &_hot {
            font-weight: 400;
            margin-left: 2vw;
            color: #5d216b;
            white-space: nowrap;
            font-size: max(12px, calc(100vw * 15px / 1920px));
            letter-spacing: -0.05vw;
          }
        }
        &_loock {
          font-size: max(12px, calc(100vw * 16px / 1920px));
          font-weight: 400;
          color: #5d216b;
          display: flex;
          > img {
            width: 1.5vw;
            height: 0.5vw;
            margin-left: 0.36vw;
            margin-top: 0.26vw;
          }
        }
      }
      &_banner {
        width: 100%;
        overflow: hidden;
        position: relative;
        :deep .el-carousel__indicator--horizontal .el-carousel__button {
          width: 0.26vw;
          height: 0.26vw;
          background-color: #ffffff;
          border-radius: 50%;
          opacity: 0.5;
        }
        :deep
          .el-carousel__indicator--horizontal.is-active
          .el-carousel__button {
          width: 0.26vw;
          height: 0.26vw;
          background: #ffffff;
          border-radius: 50%;
          opacity: 1;
          margin-bottom: 0.78vw;
        }
      }
      :deep .el-carousel__indicator--horizontal .el-carousel__button {
        width: 0.41vw;
        height: 0.41vw;
        background-color: #ffffff;
        border-radius: 50%;
        opacity: 0.5;
        margin-bottom: 0.78vw;
      }
      :deep .el-carousel__container{
        max-height: 653px !important;
      }
      :deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
        width: 0.41vw;
        height: 0.41vw;
        background: #ffffff;
        border-radius: 50%;
        opacity: 1;
        margin-bottom: 0.78vw;
      }
      :deep .el-carousel__indicators--horizontal {
        left: 92%;
      }
      &_bottom {
        margin-top: 4.53vw;

        &_item {
          &_img {
            margin-bottom: 0.78vw;
            position: relative;
           

            &_text {
              position: absolute;
              top: -2.08vw;
              left: 0.78vw;
              padding: 0.46vw 0.41vw;
              text-align: center;
              background-color: #82548d;

              > p:nth-of-type(1) {
                font-size: max(12px, calc(100vw * 30px / 1920px));
                color: #ffffff;
                font-weight: 500;
              }
              > p:nth-of-type(2) {
                font-size: max(12px, calc(100vw * 14px / 1920px));
                color: #ffffff;
                font-weight: 400;
              }
            }
          }
          &_wier {
            height: 2px;
            background-color: #5d216b;
            margin-top: 0.52vw;
          }
          &_text {
            padding-left: 0.26vw;
            font-weight: 400;
            font-size: max(12px, calc(100vw * 16px / 1920px));
            line-height: 1.56vw;
          }
        }
      }
    }
    &_right {
      &_top {
        padding: 1.04vw 0;
        border-bottom: 1px solid #e7e7e7;
        display: flex;

        &_time {
          > p {
            width: 4.5vw;
          }
          font-size: max(12px, calc(100vw * 15px / 1920px));
          font-weight: 500;
          color: #444444;
          opacity: 0.5;
          &_wier {
            width: 0.73vw;
            height: 0.1vw;
            background-color: #767676;
            margin-top: 1.15vw;
          }
        }
        &_wier {
          width: 1px;
          background-color: #e7e7e7;
          margin: 0.42vw 0.885vw;
        }
        &_text {
          overflow: hidden;
          > p:nth-of-type(1) {
            font-size: max(12px, calc(100vw * 16px / 1920px));
            font-weight: 500;
            color: #444444;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          > p:nth-of-type(2) {
            font-size: max(12px, calc(100vw * 15px / 1920px));
            font-weight: 400;
            color: #444444;
            margin-top: 0.67vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_vertical {
          width: 4px;
          height: 0.78vw;
          background-color: #5d216b;
          margin-right: 0.83vw;
          margin-top: 0.21vw;
        }
        &_box {
          display: flex;
          margin-top: 1.04vw;
        }
      }
    }
  }
  .images{
    max-height: 250px !important;
    height:10vw !important;
    overflow: hidden;
    >img{
      width: 100%;
    }
  }
  .bannerImg {
    position: relative;
    &_text {
      width: 100%;
      padding: 4% 13% 1.5vw 1.5vw;
      position: absolute;
      bottom: 0px;
      left: 0px;
      color: #ffffff;
      background: url(../assets/banner-text-bg.png) no-repeat;
      background-size: cover;
      &_title {
        font-size: max(12px, calc(100vw * 20px / 1920px));
        font-weight: 400;
      }
      &_txt {
        margin-top: 1vw;
        font-size: max(12px, calc(100vw * 16px / 1920px));
        font-weight: 400;
        line-height: 1.56vw;
      }
    }
    &_arrows {
      width: 100%;
      position: absolute;
      top: 35%;
      left: 0px;
      display: flex;
      justify-content: space-between;
      z-index: 99;
      > img {
        width: 9%;
        // height: 160px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
  .mien {
    display: flex;
    justify-content: space-between;

    &_item {
      width: 19%;
      margin-top: 5.67vw;
      overflow: hidden;
      transition: top 0.5s;
      position: relative;
      top: 0;
      &_text {
        padding: 4.32vw 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &_title {
          display: flex;
          &_txt {
            font-size: max(12px, calc(100vw * 48px / 1920px));
            font-weight: bold;
            color: #5d216b;
            writing-mode: vertical-rl;
            letter-spacing: 0.55vw;
          }
          &_hint {
            margin-left: 0.88vw;
            &_wier {
              width: 0.15vw;
              height: 1vw;
              background-color: #5d216b;
              margin-left: 0.41vw;
            }
            &_english {
              margin-top: 1vw;
              font-size: max(12px, calc(100vw * 15px / 1920px));
              font-weight: 400;
              color: #5d216b;
              writing-mode: vertical-rl;
              letter-spacing: 0.26vw;
            }
          }
        }
        &_loock {
          width: 4.95vw;
          height: 4.95vw;
          background: url(../assets/logo-min-bg.png) no-repeat;
          background-size: cover;
          padding-top: 1.77vw;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          &_img {
            width: 1.51vw;
            overflow: hidden;
          }
          &_text {
            width: 1vw;
            padding-top: 0.57vw;
            white-space: nowrap;
            writing-mode: vertical-rl;
            font-size: max(12px, calc(100vw * 16px / 1920px));
            color: #5d216b;
            font-weight: 400;
          }
        }
      }
      &_img {
        position: relative;
        &_text {
          position: absolute;
          top: 2.39vw;
          left: 2.08vw;
          font-size: max(12px, calc(100vw * 26px / 1920px));
          font-weight: 500;
          color: #ffffff;
          writing-mode: vertical-rl;
          //  letter-spacing: 5px;
        }
      }
    }
    &_item:hover {
      top: -3.59vw;
    }
    &_item:nth-of-type(1):hover {
      // margin-top: 109px;
      top: 0px;
    }
  }
  .teach {
    width: 100%;
    margin-top: 5.1vw;
    background: url(../assets/teach-bg.png) no-repeat;
    background-size: cover;

    &_top {
      display: flex;
    }
    &_box {
      width: 65%;
      padding-top: 2.81vw;

      &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &_logo {
          display: flex;
          align-items: center;
          &_img {
            width: 6.4vw;
            height: 6.4vw;
            background: url(../assets/logo-min-bg.png) no-repeat;
            background-size: cover;

            &_text {
              padding-top: 1.3vw;
              padding-bottom: 1vw;
              white-space: nowrap;
              font-size: max(12px, calc(100vw * 34px / 1920px));
              font-weight: bold;
              color: #5d216b;
              border-bottom: 0.15vw solid #5d216b;
              margin-left: -0.26vw;
            }
          }
          &_text {
            font-size: max(12px, calc(100vw * 34px / 1920px));
            font-weight: bold;
            color: #898989;
          }
        }
        &_loock {
          display: flex;
          align-items: center;
        }
      }
      &_IText {
        width: 100%;
        display: flex;

        &_item {
          width: 50%;
        }
        &_item:nth-of-type(1) {
          margin-top: 1.14vw;
        }
        &_item:nth-of-type(2) {
          margin-top: 5.6vw;
        }
      }
    }
    &_notice {
      flex: 1;
      margin-left: 3.64vw;
      background-color: #ffffff;
      padding: 2.7vw 1.56vw 0 1.97vw;
      box-shadow: 0px 0px 4.58vw 0.36vw #dee1f0;
      border-bottom: 0.36vw solid #6b3576;
      &_item {
        display: flex;
        align-items: center;
        padding: 1.82vw 0;
        border-bottom: 1px solid #ececec;
        &_time {
          > p:nth-of-type(1) {
            font-size: max(12px, calc(100vw * 36px / 1920px));
            font-weight: 800;
            color: #333333;
          }
          > p:nth-of-type(2) {
            font-size: max(12px, calc(100vw * 16px / 1920px));
            font-weight: 500;
            color: #333333;
          }
        }
        &_text {
          font-size: max(12px, calc(100vw * 16px / 1920px));
          font-weight: 500;
          color: #666666;
          line-height: 1.97vw;
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
          // 控制行数
          -webkit-line-clamp: 2; //超出两行隐藏
          -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        }
        &_wier {
          width: 1px;
          height: 2.86vw;
          background: #dddddd;
          margin: 0 1.1vw;
        }
      }
      &_loock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1.8vw;
        font-size: max(12px, calc(100vw * 16px / 1920px));
        font-weight: 400;
        color: #5d216b;
      }
    }
  }
  .teachImg {
    width: 100%;
    overflow: hidden;
  }
  .teachText {
    padding: 1.29vw 2.76vw 1.56vw 1.92vw;
    margin-top: -0.3vw;
    border-left: 0.05vw solid #5e216b21;
    &_title {
      height: 3vw;

      font-size: max(12px, calc(100vw * 18px / 1920px));
      font-weight: bold;
      color: #2e112d;
      line-height: 1.45vw;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      // 控制行数
      -webkit-line-clamp: 2; //超出两行隐藏
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素
    }
    &_action {
      color: #5d216b;
      // color: red;
    }
    &_text {
      font-size: max(12px, calc(100vw * 16px / 1920px));
      font-weight: 400;
      color: #666666;
      line-height: 1.48vw;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      // 控制行数
      -webkit-line-clamp: 3; //超出两行隐藏
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素
    }
    &_time {
      font-size: max(12px, calc(100vw * 14px / 1920px));
      font-weight: 400;
      color: #666666;
      margin-top: 1.82vw;
    }
  }
  .video {
    margin-top: 6.35vw;
    &_banner {
      margin-top: 0.5vw;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      // justify-content: space-between;
      position: relative;

      overflow: hidden;
      &_item {
        width: 25%;
        height: 11.4vw;
        box-sizing: border-box;
        overflow: hidden;

        > img {
          width: 100%;
          overflow: hidden;
        }
      }
      &_left {
        width: 3.9vw;
        height: 5.41vw;
        position: absolute;
        top: 3.8vw;
        left: 0.57vw;
      }
      &_right {
        width: 3.8vw;
        height: 5.41vw;
        position: absolute;
        top: 3.8vw;
        right: 0.57vw;
      }
      :deep .el-tag {
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
    &_text {
      display: flex;
      justify-content: space-between;
      background: url(../assets/video-text-bg.png) no-repeat;
      background-size: cover;
      margin-top: 0.78vw;
      &_item {
        width: 25%;
        padding: 2.6vw 0;
        text-align: center;
        color: #5d216b;
        font-size: max(12px, calc(100vw * 20px / 1920px));
        font-weight: bold;
        border-right: 1px solid #d8d8d8;
      }
      &_item:nth-of-type(5) {
        border: none;
      }
    }
  }

  .send {
    margin-top: 3.69vw;
    &_text {
      margin-top: 1.35vw;
      display: flex;
      &_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 2vw;
      }
      &_item {
        border: 1px solid #bfbae3;
        padding: 1.4vw;
        font-size: max(12px, calc(100vw * 18px / 1920px));
        background: url("/src/assets/send-bg.png") no-repeat;
        background-size: cover;
        background-position: center;
        color: #2e112d;
        font-weight: 400;
        line-height: 1.66vw;

        > p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .social {
    width: 100%;
    background: url(../assets/social.png) no-repeat;
    background-size: cover;
    margin-top: 4.84vw;
    &_box {
      padding: 3.43vw 8% 5.67vw;
      &_imgs {
        margin-top: 1.61vw;
        &_item {
          position: relative;
          height: 16vw;
          overflow: hidden;
          &_text {
            width: 100%;
            position: absolute;
            bottom: 0px;
            left: 0px;
            background: url(../assets/banner-text-bg.png) no-repeat;
            background-size: cover;
          }
          &_top {
            border-left: 3px solid #ffffff;
            color: #ffffff;
            font-weight: 400;
            padding-left: 0.83vw;
            > p:nth-of-type(1) {
              font-size: max(12px, calc(100vw * 14px / 1920px));
            }
            > p:nth-of-type(2) {
              font-size: max(12px, calc(100vw * 16px / 1920px));
              margin-top: 0.72vw;
            }
          }
          &_bottom {
            margin: 16px 0;
            padding-left: 16px;
            font-size: max(12px, calc(100vw * 14px / 1920px));
            color: #ffffff;
            font-weight: 400;
            display: flex;
            > img {
              width: 0.88vw;
              height: 1vw;
              margin-right: 0.41vw;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .media {
    display: flex;
    &_item {
      width: 2.76vw;
      height: 2.76vw;
      overflow: hidden;
      margin-left: 1.14vw;
      background-color: #5d216b;
      border-radius: 50%;
    }
  }
  .rapid {
    display: flex;
    justify-content: end;
    align-items: center;
    &_item {
      font-size: max(12px, calc(100vw * 14px / 1920px));
      font-weight: 400;
      color: #ffffff;
      opacity: 0.8;
      cursor: pointer;
    }
    &_wier {
      width: 0.05vw;
      height: 0.72vw;
      background: #ffffff;
      opacity: 0.3;
      margin: 0 0.72vw;
    }
    &_img {
      width: 1.09vw;
      height: 1.04vw;
      cursor: pointer;
    }
    &_text {
      font-size: max(12px, calc(100vw * 22px / 1920px));
      font-weight: 400;
      color: #ffffff;
      margin-top: 1.82vw;
      padding-bottom: 1.97vw;
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
  }
}

@media screen and (max-width: 968px) {
  ::v-deep .el-carousel__container {
    height: 50vw !important;
  }
  .home {
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    &_bg {
      background: url(http://handan-oss.oss-cn-beijing.aliyuncs.com/20240409/797420c78be581da8d5a8d6ccc0e9b27.png) no-repeat;
      background-size: 100% 100%;
    }

    &_title {
      width: 100%;
      position: relative;
      margin-top: 70px;
      &_banner {
        height: 50vw;
      }

      &_box {
        background: url(http://handan-oss.oss-cn-beijing.aliyuncs.com/20240409/797420c78be581da8d5a8d6ccc0e9b27.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        height: 12.18vw;
        margin: auto;

        &_hint {
          width: 100%;
          height: 80%;

          margin: auto;
          display: flex;
          justify-content: space-between;
          padding: 1.4vw 3.59vw 0 3vw;
        }
        &_left {
          margin-top: 0.52vw;
        }
        &_right {
          flex: 1;
          &_bottom {
            flex: 1;
          }
        }
      }
    }
  }
.imgss{
  max-height: 150px;
  overflow: hidden;
}
  .centre {
    margin-top: 0 !important;
    &_left {
      display: none;
      cursor: pointer;
      &_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0 !important;
        &_hotspot {
          width: 15.4vw;
          height: 15.4vw;
          background: url(../assets/logo-bg.png) no-repeat;
          background-size: 100% 100%;
          padding-top: 3vw;
          &_dat {
            white-space: nowrap;
            font-size: 17px;
            color: #5d216b;
            font-weight: bold;
            letter-spacing: 0.1vw;
            margin-left: -0.26vw;
          }
          &_hot {
            font-weight: 400;
            margin-left: 20px;
            color: #5d216b;
            white-space: nowrap;
            font-size: 7px;
            letter-spacing: -0.05vw;
          }
        }
        &_loock {
          font-size: 12px;
          font-weight: 400;
          color: #5d216b;
          display: flex;
          align-items: center;
          > img {
            width: 20px !important;
            height: 5px !important;
            margin-left: 0.36vw;
            margin-top: 0.26vw;
          }
        }
      }
      &_banner {
        width: 100%;
        overflow: hidden;
        position: relative;
        :deep .el-carousel__indicator--horizontal .el-carousel__button {
          width: 0.26vw;
          height: 0.26vw;
          background-color: #ffffff;
          border-radius: 50%;
          opacity: 0.5;
        }
        :deep
          .el-carousel__indicator--horizontal.is-active
          .el-carousel__button {
          width: 0.26vw;
          height: 0.26vw;
          background: #ffffff;
          border-radius: 50%;
          opacity: 1;
          margin-bottom: 0.78vw;
        }
      }
      :deep .el-carousel__indicator--horizontal .el-carousel__button {
        width: 0.41vw;
        height: 0.41vw;
        background-color: #ffffff;
        border-radius: 50%;
        opacity: 0.5;
        margin-bottom: 0.78vw;
      }
      :deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
        width: 0.41vw;
        height: 0.41vw;
        background: #ffffff;
        border-radius: 50%;
        opacity: 1;
        margin-bottom: 0.78vw;
      }
      :deep .el-carousel__indicators--horizontal {
        left: 92%;
      }
      &_bottom {
        margin-top: 4.53vw;
        display: none;
        &_item {
          &_img {
            margin-bottom: 0.78vw;
            position: relative;
            &_text {
              position: absolute;
              top: -2.08vw;
              left: 0.78vw;
              padding: 0.46vw 0.41vw;
              text-align: center;
              background-color: #82548d;

              > p:nth-of-type(1) {
                font-size: calc(100vw * 30px / 1920px);
                color: #ffffff;
                font-weight: 500;
              }
              > p:nth-of-type(2) {
                font-size: calc(100vw * 14px / 1920px);
                color: #ffffff;
                font-weight: 400;
              }
            }
          }
          &_wier {
            height: 2px;
            background-color: #5d216b;
            margin-top: 0.52vw;
          }
          &_text {
            padding-left: 0.26vw;
            font-weight: 400;
            font-size: calc(100vw * 16px / 1920px);
            line-height: 1.56vw;
          }
        }
      }
    }
    &_right {
      &_top {
        padding: 1.04vw 0;
        border-bottom: 1px solid #e7e7e7;
        display: flex;
        &_time {
          > p {
            width: 17vw;
          }
          font-size: 12px;
          font-weight: 500;
          color: #444444;
          opacity: 0.5;
          &_wier {
            width: 0.73vw;
            height: 0.1vw;
            background-color: #767676;
            margin-top: 1.15vw;
          }
        }
        &_wier {
          width: 1px;
          background-color: #e7e7e7;
          margin: 0.42vw 0.885vw;
        }
        &_text {
          padding-left: 5px;
          overflow: hidden;
          > p:nth-of-type(1) {
            font-size: 15px;
            font-weight: 500;
            color: #444444;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          > p:nth-of-type(2) {
            font-size: 13px;
            font-weight: 400;
            color: #444444;
            margin-top: 0.67vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &_vertical {
          width: 4px;
          height: 1.78vw;
          background-color: #5d216b;
          margin-right: 0.83vw;
          margin-top: 1.21vw;
        }
        &_box {
          display: flex;
          padding-top: 20px;
        }
      }
    }
  }
 
  .bannerImg {
    position: relative;
    &_text {
      width: 100%;
      padding: 4% 13% 1.5vw 1.5vw;
      position: absolute;
      bottom: 0px;
      left: 0px;
      color: #ffffff;
      background: url(../assets/banner-text-bg.png) no-repeat;
      background-size: cover;
      &_title {
        font-size: calc(100vw * 20px / 1920px);
        font-weight: 400;
      }
      &_txt {
        margin-top: 1vw;
        font-size: calc(100vw * 16px / 1920px);
        font-weight: 400;
        line-height: 1.56vw;
      }
    }
    &_arrows {
      width: 100%;
      position: absolute;
      top: 35%;
      left: 0px;
      display: flex;
      justify-content: space-between;
      z-index: 99;
      > img {
        width: 9%;
        // height: 160px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
  .mien {
    width: 100vw !important;
    display: flex;
    flex-wrap: nowrap;
    // justify-content: space-between;
    overflow-x: auto !important;

    &_item {
      width: 120px !important;
      height: 100% !important;
      margin-top: 5.67vw;
      transition: top 0.5s;
      position: relative;
      margin-right: 24px;
      top: 0;
      &_text {
        padding: 4.32vw 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &_title {
          display: flex;
          &_txt {
            font-size: 17px;
            font-weight: bold;
            color: #5d216b;
            writing-mode: vertical-rl;
            letter-spacing: 0.55vw;
          }
          &_hint {
            margin-left: 0.88vw;
            &_wier {
              width: 0.15vw;
              height: 1vw;
              background-color: #5d216b;
              margin-left: 0.41vw;
            }
            &_english {
              margin-top: 1vw;
              font-size: 8px;
              font-weight: 400;
              color: #5d216b;
              writing-mode: vertical-rl;
              letter-spacing: 0.26vw;
            }
          }
        }
        &_loock {
          width: 4.95vw;
          height: 4.95vw;

          padding-top: 1.77vw;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          &_img {
            width: 1.51vw;
            overflow: hidden;
          }
          &_text {
            width: 1vw;
            padding-top: 0.57vw;
            white-space: nowrap;
            writing-mode: vertical-rl;
            font-size: 12px;
            color: #5d216b;
            font-weight: 400;
          }
        }
      }
      &_img {
        width: 120px !important;

        position: relative;
        &_text {
          position: absolute;
          top: 2.39vw;
          left: 2.08vw;
          font-size: 13px;
          font-weight: 500;
          color: #ffffff;
          writing-mode: vertical-rl;
          //  letter-spacing: 5px;
        }
      }
    }
    &_item:nth-of-type(4) {
      margin-right: 0;
    }
    &_item:hover {
      top: -3.59vw;
    }
  }
  .teach {
    width: 100%;
    margin-top: 5.1vw;
    background: url(../assets/teach-bg.png) no-repeat;
    background-size: cover;

    &_box {
      width: 100%;
      padding-top: 2.81vw;

      &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &_logo {
          display: flex;
          align-items: center;
          justify-content: center;
          &_img {
            width: 15.4vw;
            height: 15.4vw;
            background: url(../assets/logo-min-bg.png) no-repeat;
            background-size: cover;

            &_text {
              padding-top: 3vw;
              padding-bottom: 1vw;
              white-space: nowrap;
              font-size: 17px;
              font-weight: bold;
              color: #5d216b;
              border-bottom: 0.15vw solid #5d216b;
              margin-left: -0.26vw;
            }
          }
          &_text {
            font-size: 17px;
            font-weight: bold;
            color: #898989;
            margin-left: 30px;
          }
        }
        &_loock {
          display: flex;
          align-items: center;
          color: #5d216b !important;
          > img {
            width: 4vw !important;
            height: 1vw !important;
          }
        }
      }
      &_IText {
        width: 100%;
        display: flex;

        &_item {
          width: 50%;
        }
        &_item:nth-of-type(1) {
          margin-top: 1.14vw;
        }
        &_item:nth-of-type(2) {
          margin-top: 5.6vw;
        }
      }
    }
    &_notice {
      width: 100%;
      background-color: #ffffff;
      padding: 2.7vw 1.56vw 0 1.97vw;
      box-shadow: 0px 0px 4.58vw 0.36vw #dee1f0;
      border-bottom: 0.36vw solid #6b3576;
      &_item {
        display: flex;
        align-items: center;
        padding: 1.82vw 0;
        border-bottom: 1px solid #ececec;
        &_time {
          > p:nth-of-type(1) {
            font-size: 18px;
            font-weight: 800;
            color: #333333;
          }
          > p:nth-of-type(2) {
            font-size: 8px;
            font-weight: 500;
            color: #333333;
          }
        }
        &_text {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
          // 控制行数
          -webkit-line-clamp: 2; //超出两行隐藏
          -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        }
        &_wier {
          width: 1px;
          height: 2.86vw;
          background: #dddddd;
          margin: 0 1.1vw;
        }
      }
      &_loock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1.8vw;
        font-size: 12px;
        font-weight: 400;
        color: #5d216b;
        > img {
          width: 20px !important;
          height: 4px !important;
          margin-left: 6px !important;
        }
      }
    }
  }
  .teachImg {
    width: 100%;
    overflow: hidden;
  }
  .teachText {
    padding: 1.29vw 2.76vw 1.56vw 1.92vw;
    margin-top: -0.3vw;
    border-left: 0.05vw solid #5e216b21;
    &_title {
      font-size: 14px;
      font-weight: bold;
      color: #5d216b;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      // 控制行数
      -webkit-line-clamp: 2; //超出两行隐藏
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素
    }
    &_text {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      // 控制行数
      -webkit-line-clamp: 2; //超出两行隐藏
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素
    }
    &_time {
      font-size: 7px;
      font-weight: 400;
      color: #666666;
      margin-top: 1.82vw;
    }
    > img {
      width: 14px !important;
      height: 4px !important;
    }
  }
  .video {
    margin-top: 6.35vw;
    &_banner {
      margin-top: 0.5vw;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      // justify-content: space-between;
      position: relative;

      overflow: hidden;
      &_item {
        width: 25%;
        height: 11.4vw;
        box-sizing: border-box;
        overflow: hidden;

        > img {
          width: 100%;
          overflow: hidden;
        }
      }
      &_left {
        width: 3.9vw;
        height: 5.41vw;
        position: absolute;
        top: 3.8vw;
        left: 0.57vw;
      }
      &_right {
        width: 3.8vw;
        height: 5.41vw;
        position: absolute;
        top: 3.8vw;
        right: 0.57vw;
      }
      :deep .el-tag {
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
    &_text {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      background: url(../assets/video-text-bg.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 0.78vw;
      &_item {
        width: 50%;
        padding: 2.6vw 0;
        text-align: center;
        color: #5d216b;
        font-size: 15px;
        font-weight: bold;
      }
      &_item:nth-of-type(5) {
        border: none;
      }
    }
  }

  .send {
    margin-top: 3.69vw;
    &_text {
      margin-top: 1.35vw;
      // display: flex;
      &_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // padding-left: 2vw;
      }
      &_item {
        border: 1px solid #bfbae3;
        padding: 1.4vw;
        font-size: 14px;
        background: url("/src/assets/send-bg.png") no-repeat;
        background-size: cover;
        background-position: center;
        color: #2e112d;
        font-weight: 400;
        margin-top: 13px;
        > p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .social {
    width: 100%;
    overflow: hidden;
    background: url(../assets/social.png) no-repeat;
    background-size: cover;
    margin-top: 4.84vw;
    &_box {
      &_imgs {
        padding-bottom: 30px;
        margin-top: 1.61vw;
        &_item {
          position: relative;
          overflow: hidden;
        margin-top: -10px;
        
          &_text {
            width: 100%;
            position: absolute;
            bottom: 5px;
            left: 0px;
            background: url(../assets/banner-text-bg.png) no-repeat;
            background-size: 100% 100%;
          }
          &_top {
            border-left: 3px solid #ffffff;
            color: #ffffff;
            font-weight: 400;
            padding-left: 0.83vw;
            > p:nth-of-type(1) {
              font-size: 10px;
            }
            > p:nth-of-type(2) {
              font-size: 12px;
              margin-top: 5px;
            }
          }
          &_bottom {
            margin: 16px 0;
           
            padding-left: 16px;
            font-size: 10px;
            color: #ffffff;
            font-weight: 400;
            display: flex;
            align-items: center;
            > img {
              width: 16px !important;
              height: 16px !important;
              margin-right: 0.41vw;
              overflow: hidden;
            }
          }
        }
        &_item:nth-of-type(n + 4) {
          display: none;
        }
      }
    }
  }
  .media {
    display: flex;
  
    &_item {
      width: 20px;
      height: 20px;
      overflow: hidden;
      margin-left: 1.14vw;
      background-color: #5d216b;
      border-radius: 50%;
    }
  }
  .rapid {
    display: flex;
    justify-content: end;
    align-items: center;
    &_item {
      font-size: calc(100vw * 14px / 1920px);
      font-weight: 400;
      color: #ffffff;
      opacity: 0.8;
      cursor: pointer;
    }
    &_wier {
      width: 0.05vw;
      height: 0.72vw;
      background: #ffffff;
      opacity: 0.3;
      margin: 0 0.72vw;
    }
    &_img {
      width: 1.09vw;
      height: 1.04vw;
      cursor: pointer;
    }
    &_text {
      font-size: calc(100vw * 22px / 1920px);
      font-weight: 400;
      color: #ffffff;
      margin-top: 1.82vw;
      padding-bottom: 1.97vw;
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
  }
}
</style>